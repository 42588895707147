import React, {useEffect, useState} from "react"
import OrderApprovementComp from "../../shared/OrderApprovmentComp";
import {useHistory, useParams} from "react-router-dom";
import {PUBLIC_ROUTE} from "../../route.constants";
import ProjectService from "../../services/ProjectService";
import Topbar from "../Topbar/Topbar";
import {Button, Col, Row} from "antd";
import Loader from "@iso/components/utility/loader";
import Modal from "@iso/components/Feedback/Modal";
export default function(props) {

  const history = useHistory();
  let { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [approved, setApproved] = useState(false);
  const [saveLoading, setsaveLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updatedTimestamp, setUpdatedTimestamp] = useState(new Date().getTime());
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    ProjectService.getProjectDetailsForPublic(id).then((p) => {
      if (p) {
        if (p.error && p.errorType) {
          setError(p);
        }
        else {
          setProject(p);
        }
      }

    }).finally(() => {
      setLoading(false)
    });
  }, [])

  const onDiscardOrder = () => {
    setsaveLoading(true);

    ProjectService.discardProject(project).then(() => {
      setsaveLoading(false);
      setApproved(true);
      Modal.info({
        title: 'Ihre Bestellung wurde abgelehnt.',
        content: 'Wir bedauern es sehr dass Sie unsere Bestellung nicht beauftragt haben. ',
        okText: 'Ok',
        onOk: () => {

        }

      });

    })
  }

  const setPaymentOption = (product, option) => {
    product.priceOptionId = option.Id;
    product.Amount = option.Amount;
    product.Price = option.Price;
    product.TotalUnitPrice = option.Price;
    setProject(project);
    setUpdatedTimestamp(new Date().getTime());
  }

  const onApproveOrder = () => {
    if(validated) {
      setsaveLoading(true);

      ProjectService.approveProject(project).then(() => {
        setsaveLoading(false);
        setApproved(true);
        Modal.info({
          title: 'Projekt bestellt - Bearbeitung startet',
          content: 'Glückwunsch – Sie haben soeben Ihre Bestellung bei uns aufgegeben. Wir werden uns umgehend bei Ihnen mit den nächsten Schritten melden. In einer E-Mail erhalten Sie nochmals alle Details dieser Bestellung.',
          okText: 'Ok',
          onOk: () => {

          }

        });

      })
    } else {

    }

  }

  const showPriceInfo = () => {

  }

  return (
    <div>
      {error && <div style={{padding: 20,paddingTop: 20}}>
          <h2>Fehler - nicht erlaubt</h2>
          <div>Das Projekt hat nicht den richtigen Status und besitzt ebenfalls nicht den richtigen Typ.</div>
      </div>}
      {!project && !error && <Loader />}
      {project && approved && <div className={"order-approved"}>
        <h2>Vielen Dank für Ihre Bestellung</h2>
        <span>Diese Bestellung wurde bereits abgesendet, sie können das Fenster nun schließen.</span>
      </div>}
      {project &&
          <div style={{padding: 20,paddingTop: 20}}>
            <div style={{padding:20, paddingTop: 10, fontSize: 26}}>
              Freigabe Ihrer Bestellung #{project.Number} / {project.Id}
            </div>
          <Row>
            <Col lg={14} xl={14} md={24} sm={24} xs={24}>
                  <div  style={{paddingLeft: 20}}>
                    Auf dieser Seite können Sie Ihr Projekt bei uns bestellen und somit direkt die Bearbeitung beauftragen.
                  </div>
                  <div  style={{padding:20}}>
                    {loading && <Loader />}
                    {(!loading) &&
                        <OrderApprovementComp company={project?.company}
                                              updatedTimestamp={updatedTimestamp}
                                              projectId={project?.Id}
                                              onChecked={(val) => {setValidated(val)}}
                                              setPaymentOption={setPaymentOption} approved={approved}
                                              validated={validated}
                                              project={project} /> }
                  </div>

            </Col>
            <Col lg={8} xl={8} md={24} sm={24}>
              <div style={{padding: 20}}>
                <div style={{paddingTop: 0}}>
                  <div style={{fontWeight: "bold", marginBottom: 10, fontSize: 16}}>Kundendaten</div>
                  <div dangerouslySetInnerHTML={{__html: project?.company?.Name}}></div>
                  <div>{project?.company?.DefaultAddress?.ZipCode + " " + project?.company?.DefaultAddress?.City}</div>
                  <div><span dangerouslySetInnerHTML={{__html: project?.company?.DefaultAddress?.Street}} /> {project?.company?.DefaultAddress?.HouseNumber}</div>
                </div>
                <div style={{paddingTop: 20}}>
                  <div style={{fontWeight: "bold", marginBottom: 10, fontSize: 16}}>Ansprechpartner Agentur</div>
                  {project?.keyAccounts && project.keyAccounts.map((keyaccount) => {
                    return <div style={{marginTop: "20px"}}>
                      <div>{keyaccount.Salutation} {keyaccount.FirstName} {keyaccount.LastName}</div>
                      <div>{keyaccount.EMailWork}</div>
                      <br/>
                    </div>
                  })}
                </div>
              </div>
            </Col>
          </Row>
            <Button size={"large"} loading={saveLoading} disabled={approved || !validated} onClick={onApproveOrder} style={{marginTop: 20, marginLeft: 20}} type={"primary"}>Kostenpflichtig bestellen</Button>
            <Button size={"large"} style={{marginLeft: 20}} disabled={saveLoading || approved} onClick={onDiscardOrder} type={"secondary"}>Ablehnen</Button>
          </div>}


    </div>


  )
}
